import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article07072020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T Mold invites you to take part in the partner webinar
            </h1>
            <p>
              Aruba Instant On Solution S&T Mold invites you to take part in the
              partner webinar. During this session, Aruba will briefly cover the
              importance of WPA2-Enterprise authentication and demonstrate a new
              cloud-based Aruba Instant ON solution.{" "}
            </p>
            <p>
              With Aruba Instant On, you’re getting a combination of world-class
              performance, network security, and simplified setup and management
              to meet your specific needs. More than that, you’re acquiring
              premium hardware and software that’s scalable, cost-effective, and
              supportive of emerging apps and IoT—all while delivering the best
              user and IT experience possible.{" "}
            </p>
            <p className="bold">
              On 14-th of July Almira Aytekova will cover:{" "}
            </p>
            <p>
              Aruba Instant On Solution: The Always-On Network For The Always-On
              Business{" "}
            </p>
            <p>Introducing Aruba Instant On Switches </p>
            <p>Warranty and Support Services </p>
            <p>Pricing</p>
            <p>Instant On Wireless Software </p>
            <p>Q&A</p>
            <p className="bold">Webinar details: </p>
            <p>Date: 14 July 2020 </p>
            <p>Time: 11:15 – 12:00| Chisinau, Moldova </p>
            <p>
              Speaker: Almira Aytekova, Technical Sales Representative Aruba, a
              Hewlett Packard Enterprise company{" "}
            </p>
            <p>Language: Russian </p>
            <p className="bold">
              Registration{" "}
              <a href="https://arubanetworks.zoom.us/webinar/register/WN_6m9czTsWTqmKgcP1JQN_1Q">
                Link
              </a>
            </p>
            <p>We look forward to your participation!</p>
            <p>Your S&T Mold team</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article07072020;
